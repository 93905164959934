import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import Typography from '../Typography'
import Markdown from '../Markdown'
import Image from 'src/components/Image'

const Wrapper = styled.div`
  overflow: hidden;
`

const StyledGrid = styled.div`
  column-fill: balance;
  margin-top: -60px;
  column-gap: 30px;
  columns: 1;

  ${up('sm')} {
    columns: 2;
  }

  ${up('md')} {
    columns: 3;
  }
`

const StyledItem = styled.div`
  break-inside: avoid;
  padding-top: 60px;
  position: relative;
  display: inline-block;

  &:before {
    content: '';
    background: currentColor;
    position: absolute;
    display: block;
    height: 1px;
    width: 100%;
    top: 30px;
  }
`
const StyledMarkdown = styled(Markdown)`
  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`
const StyledTitle = styled.div`
  margin: 5px 0 10px;
`

const Link = styled.a`
  font-family: ${({ theme }) => theme.typo.primary};
  font-weight: 500;
  margin-top: 15px;
  font-size: 14px;
  display: inline-block;
  color: currentColor;
`

const StyledImage = styled(Image)`
  margin-top: 20px;
`
const Copyright = styled.div`
  margin-top: 10px;
`

const News = props => {
  const { className, items } = props

  return (
    <Wrapper className={className}>
      <StyledGrid>
        {items.map((item, key) => (
          <StyledItem key={key}>
            <Typography variant="newsMeta">{item.created}</Typography>
            <StyledTitle>
              <Typography variant="h3" as="div">
                {item.title}
              </Typography>
            </StyledTitle>
            <Typography variant="body">
              <StyledMarkdown source={item.text} />
            </Typography>

            {item.link && (
              <Link
                href={item.link}
                target="_blank"
                rel="nofollow"
                color="link"
              >
                {item.label || item.link}
              </Link>
            )}

            {item.cover && item.cover.local && (
              <>
                <StyledImage fluid={item.cover.local.childImageSharp.fluid} />
                {item.copyright && (
                  <Copyright>
                    <Typography variant="newsMeta">{item.copyright}</Typography>
                  </Copyright>
                )}
              </>
            )}
          </StyledItem>
        ))}
      </StyledGrid>
    </Wrapper>
  )
}

News.propTypes = {
  items: PropTypes.array,
}

News.defaultProps = {
  items: [],
}

export default News

export const query = graphql`
  fragment NewsFragment on news {
    title
    text
    created_at
    created: created_at(formatString: "DD. MMMM YYYY", locale: "de")
    label
    link
    copyright
    cover {
      local {
        childImageSharp {
          fluid(maxWidth: 1118, quality: 60) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`

import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../layout'
import BackgroundVideo from 'src/components/BackgroundVideo'
import FlexibleLogo from 'src/components/FlexibleLogo'
import HeroContent from 'src/components/HeroContent'
import Typography from 'src/components/Typography'
import Button from 'src/components/Button'
import Hero from 'src/components/Hero'
import Section from 'src/components/Section'
import Container from 'src/components/Container'
import Grid from 'src/components/Grid'
import Image from 'src/components/Image'
import { Box } from '@material-ui/core'
import FullTeaser from 'src/components/FullTeaser'
import List from 'src/components/List'
import GradientOverlay from 'src/components/GradientOverlay'
import jobDate from 'src/helper/jobDate'
import ProjectsSlider from 'src/components/ProjectsSlider'
import FocusPoints from 'src/components/FocusPoints'
import News from 'src/components/News'
// import StorySlider from 'src/components/StorySlider'

const IndexPage = props => {
  const {
    imageKarriere: { nodes: imageKarriere },
    imagePoster: { nodes: imagePoster },
    // stories: { nodes: stories },
    news: { nodes: news },
    focuses: { nodes: focuses },
    focusTeaser: { nodes: focusTeaser },
    jobs: { nodes: jobs },
    pages: { nodes: pages },
    featuredProjects: { nodes: featuredProjects },
  } = props.data

  const {
    featured,
    metaTitle,
    metaDescription,
    metaImage,
    contactTitle,
    contactText,
    contact,
  } = pages[0]

  const metaProps = {
    metaTitle,
    metaDescription,
    metaImage,
  }

  const contactProps = {
    contactTitle,
    contactText,
    contact,
  }

  return (
    <Layout meta={metaProps} contact={contactProps}>
      {/*
            Hero
      */}
      <Section border={false} flush>
        <Hero transparent>
          <BackgroundVideo
            {...{
              videoID: 'https://player.vimeo.com/video/384528532',
              fluid: imagePoster[0].childImageSharp.fluid,
              autoplay: true,
            }}
          />
          <GradientOverlay color="bottomLeft" multiply />
          <FlexibleLogo color="primary" size={80} />
          <HeroContent>
            <Typography variant="hero" paragraph>
              Ganzheitliche Architektur entsteht, wo Empathie und Prozesse
              ineinandergreifen
            </Typography>
          </HeroContent>
        </Hero>
      </Section>

      {/*
            Intro
      */}
      <Section color="primary" border={false} flush>
        <Container>
          <Typography variant="fullTeaser">
            <p>
              Wir begreifen die Bedürfnisse und Erwartungen von Menschen als das
              Fundament unserer Arbeit. Gepaart mit langjähriger Erfahrung
              verbinden wir so Nutzen mit Ästhetik und schaffen nachhaltige
              Lebensräume.
            </p>
            <p>
              Wir sind{' '}
              <Link to="/profil">
                <u>Hahn Helten Architektur</u>
              </Link>
              .
            </p>
          </Typography>
        </Container>
      </Section>

      {news && news.length > 0 && (
        <Section color="secondary">
          <Container>
            <Typography variant="h1" paragraph>
              Aktuelles
            </Typography>
            <News items={news} />
          </Container>
        </Section>
      )}

      {/*
            Projects
      */}
      {featuredProjects && featuredProjects.length > 0 && (
        <Section color="secondary">
          <Container>
            <Typography variant="h1" paragraph>
              Laufende Projekte
            </Typography>
            <ProjectsSlider projects={featuredProjects} randomize={true} />
          </Container>
        </Section>
      )}

      {/*
            Schwerpunkte
      */}
      <Section>
        <Container>
          <Typography variant="h1" paragraph>
            Schwerpunkte
          </Typography>
          <FocusPoints {...{ focuses }} />
        </Container>
      </Section>

      {/*
            Full Teaser Projects
      */}
      {focusTeaser[0].cover && focusTeaser[0].cover.local && (
        <Section border={false}>
          <FullTeaser
            imgFluid={focusTeaser[0].cover.local.childImageSharp.fluid}
          >
            <Typography variant="fullTeaser" paragraph>
              Wir machen auch Sachen, die in keine Schublade passen. Neugierig?
            </Typography>
            <Typography variant="body" paragraph>
              Abseits unserer Schwerpunkte haben wir noch mehr zu bieten.
              Entdecken Sie unsere etwas anderen Projekte.
            </Typography>
            <Button color="white" to="/schwerpunkte/ohne-schublade">
              Projekte ansehen
            </Button>
          </FullTeaser>
        </Section>
      )}

      {/*
            Split Section (Career Jobs / Image)
      */}
      <Section color="secondary">
        <Container>
          <Grid gap={false} variant="2x">
            <div style={{ maxWidth: '440px' }}>
              <div style={{ marginBottom: '40px' }}>
                <Typography variant="h1" paragraph>
                  Karriere
                </Typography>
                <Typography variant="body" paragraph>
                  Arbeiten Sie gemeinsam mit uns an abwechslungsreichen
                  Projekten und bringen Sie Ihre Neugierde, Kreativität und
                  strukturierte Arbeitsweise in unser Planungsteam ein.
                </Typography>
                <Button color="secondary" to="/karriere">
                  Zur Karriereseite
                </Button>
              </div>
              <Box>
                <Typography variant="h3" paragraph>
                  Aktuelle Jobs
                </Typography>
                <List color="secondary">
                  {jobs.map((entry, key) => (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      key={key}
                    >
                      <Box mr={1}>
                        <Typography variant="listTitle">
                          {entry.title}
                        </Typography>
                      </Box>
                      <Button color="secondary" to={'/jobs/' + entry.slug}>
                        {jobDate(entry.created)}
                      </Button>
                    </div>
                  ))}
                </List>
              </Box>
            </div>
            <div>
              <Image fluid={imageKarriere[0].childImageSharp.fluid} />
            </div>
          </Grid>
        </Container>
      </Section>

      {/*
            Stories
      */}
      {/*
      <Section color="secondary">
        <Container>
          <Typography variant="h1" paragraph>
            Stories
          </Typography>
          <StorySlider {...{ stories }} />
        </Container>
      </Section>
      */}
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    pages: allPages(filter: { id__normalized: { eq: "home" } }) {
      nodes {
        contactTitle
        contactText
        contact {
          ...ContactFragment
        }
        featured {
          title
          slug
          intro
          createPage
          cover {
            local {
              childImageSharp {
                fluid(maxWidth: 1280, quality: 60) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
        metaTitle
        metaDescription
        metaImage {
          local {
            childImageSharp {
              fixed(width: 1024, quality: 60) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }

    focuses: allFocuses(
      filter: {
        slug: { nin: ["ohne-schublade", "kein-schwerpunkt"] }
        id: { ne: "dummy" }
      }
    ) {
      nodes {
        ...FocusPointsFragment
      }
    }

    featuredProjects: allProjects(filter: { status: { eq: "unlisted" } }) {
      nodes {
        title
        slug
        intro
        createPage
        cover {
          local {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 60) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }

    focusTeaser: allFocuses(filter: { slug: { eq: "ohne-schublade" } }) {
      nodes {
        cover {
          local {
            childImageSharp {
              fluid(maxWidth: 800, quality: 60) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }

    jobs: allJobs(filter: { id: { ne: "dummy" } }) {
      nodes {
        title
        slug
        created: created_at
      }
    }

    imagePoster: allFile(filter: { relativePath: { eq: "poster_index.jpg" } }) {
      nodes {
        publicURL
        childImageSharp {
          fluid(maxWidth: 3840) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }

    news: allNews(
      filter: { id: { ne: "dummy" } }
      sort: { order: DESC, fields: created_at }
      limit: 6
    ) {
      nodes {
        ...NewsFragment
      }
    }

    imageKarriere: allFile(
      filter: { relativePath: { eq: "karriere_teaser_01.jpg" } }
    ) {
      nodes {
        publicURL
        childImageSharp {
          fluid(maxWidth: 1080) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  }
`

// stories: allStories(limit: 9, filter: { id: { ne: "dummy" } }) {
//   nodes {
//     ...StorySliderFragment
//   }
// }
